'use client'
import React, { Fragment, useEffect, useState } from 'react'
import logoLO from "@/assets/navbar/logo.png"
import Image from 'next/image'
import navbarList from "@/lang/id/navbar.json"
import { list_navbar } from '@/helpers/content/id/navbar'
import Link from 'next/link'
import { montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'

export default function Navbar({moveToSection, setnextTab}) {

  const [yOffset, setYOffset] = useState(0);
  const [hover, sethover] = useState(false)
  const [hoverActive, sethoverActive] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      // console.log(window.scrollY)
      setYOffset(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleHover = (name) => {
    sethover(true)
    sethoverActive(name)
  }

  const handleBlur = () => {
    sethover(false)
    sethoverActive(null)
  }

  const handleRedirect = (value,index) => {
    console.log(value,index)
    if (value.description) {
      setnextTab(index)
      moveToSection('services')
    }else{
      moveToSection(value.type)
    }
  }

  return (
    <nav className={`flex ${yOffset > 0 ? "bg-base-white shadow-[0_8px_40px_0px_rgba(55,65,81,0.20)] z-50" : "z-50"} fixed top-0 w-full xl:mx-auto text-base lg:justify-between py-6 px-8 flex-col lg:flex-row lg:items-center`}>
        <div className='max-w-[1280px] lg:container lg:mx-auto flex lg:items-center lg:justify-between xl:justify-between xl:items-center w-full'>
          <div onClick={() => moveToSection("home")} className='hover:cursor-pointer'>
            <Image src={logoLO}  className='w-[154px]'/>
          </div>
          <ul className={`${montserratSemibold.className} flex text-base space-x-8`}>
            {
              list_navbar.data.map((v) => {
                return v.position == "center" ? v.list.map((value,index) => (
                  <li key={index} onMouseEnter={() => handleHover(value.name)} onMouseLeave={() => handleBlur()} className='relative flex'>
                    <div onClick={() => value.link ? window.open(value.link, "_self") : moveToSection(value.type)} className='hover:bg-gray-cool-100 hover:cursor-pointer text-gray-cool-700 rounded-lg px-6 py-3' >{value.name}</div>
                    {
                      value.sub_list.length > 0 && value.name == hoverActive && hover ? (
                        <div className={` ${value.sub_list[0].description ? "w-[342px]" : "w-[144px]"} flex z-50 flex-col absolute left-0 top-12 bg-base-white rounded drop-shadow-lg`}>
                          {
                            value.sub_list.map((vs, is) => (
                              <div onClick={() => vs.link ? window.open(vs.link,"_self") : moveToSection(vs.type)} className={`${is == (value.sub_list.length-1) ? "" : "border-b"} py-3 hover:cursor-pointer hover:bg-gray-cool-100 border-gray-cool-200 text-gray-cool-500 flex flex-col pt-3 px-6`} key={is}>
                                <p className={`${montserratSemibold.className} text-base-black`}>{vs.name}</p>
                                <p className={`${montserratNormal.className} text-gray-cool-500 text-sm`}>{vs.description}</p>
                              </div>
                            ))
                          }
                        </div>
                      ) : ""
                    }
                  </li>
                )) : ""
              })
            }
          </ul>
          <ul className={`${montserratSemibold.className} flex space-x-8`}>
            {
              list_navbar.data.map((v) => {
                return v.position == "end" ? v.list.map((value,index) => (
                  <li key={index} className='relative flex'>
                    {
                      value.type == "signin" ? (
                        <Link className={`${value.type_button ? "border border-gray-cool-300 rounded-lg" : ""} hover:cursor-pointer hover:bg-gray-cool-100 text-gray-cool-700 rounded-lg px-6 py-3`} target='__blank' href={value.link}>{value.name}</Link>
                      ) : (
                        <div onClick={() => moveToSection(value.type)} className={`${value.type_button ? "border border-gray-cool-300 rounded-lg" : ""} hover:bg-gray-cool-100 hover:cursor-pointer text-gray-cool-700 rounded-lg px-6 py-3`} href={value.link}>{value.name}</div>
                      )
                    }
                  </li>
                )) : ""
              })
            }
          </ul>
        </div>
    </nav>
  )
}
