import React, { Fragment, useEffect, useState } from 'react'
import logoLO from "@/assets/navbar/logo.png"
import Image from 'next/image'
import {Bars3Icon, ChevronDownIcon, ChevronUpIcon, XMarkIcon} from "@heroicons/react/24/outline"
import { list_navbar } from '@/helpers/content/id/navbar'
import Link from 'next/link'
import { montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'

function NavbarMobile({moveToSection, setnextTab,setisShowBar,isShowBar}) {
  const [isShowSubList, setisShowSubList] = useState(false)
  const [choosedMenu, setchoosedMenu] = useState(null)

  const [yOffset, setYOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
    //   console.log(window.scrollY)
      setYOffset(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOpenSublist = async (value) => {
    // console.log(value)
    // console.log(isShowSubList)
    if(value.sub_list.length > 0) {
        if (isShowSubList && value.name == choosedMenu) {
            setisShowSubList(false)
        }else{
            setisShowSubList(true)
        }
        setchoosedMenu(value.name)
    }else{
        setisShowSubList(prev => !prev)
    }
  }

  const handleRedirect = (sub, index) => {
    if (sub.description) {
        moveToSection('services-mobile', index)
    }else{
        moveToSection(sub.type)
    }
  }

  return (
    <Fragment>
        <nav className={`flex ${yOffset > 30 ? "shadow-[0_8px_40px_0px_rgba(55,65,81,0.20)] z-50" : "z-50"} bg-base-white z-50 fixed top-0 flex-col w-full`}>
            <div className='flex w-full mx-auto px-4 py-2 justify-between items-center'>
                <div onClick={() => window.open("/","_self")} className='hover:cursor-pointer'>
                    <Image src={logoLO}  className='w-[154px] '/>
                </div>
                <div onClick={() => setisShowBar(prev => !prev)} className={`${isShowBar ? "" : ""} flex p-3 justify-end`}>
                    {
                        isShowBar ? (
                            <XMarkIcon width={20} height={20} />
                        ) : (
                            <Bars3Icon width={20} height={20}/>
                        )
                    }
                </div>
            </div>
            {
                isShowBar && (
                    <Fragment>
                        <ul className='border w-full h-auto px-4 py-2 border-gray-cool-300'>
                            {
                                list_navbar.data.map((v) => {
                                return v.position == "center" ? v.list.map((value,index) => (
                                    <li key={index} onClick={() => {value.sub_list.length > 0 ? handleOpenSublist(value) : moveToSection(value.type)}}  className='flex flex-col'>
                                        <div className='flex w-full justify-between items-center'>
                                            <div onClick={() => value.link ? window.open(value.link, "_self") : moveToSection(value.type)} className={`${montserratNormal.className} py-3 text-gray-cool-700 rounded-lg`}>{value.name}</div>
                                            {
                                                value.sub_list.length > 0 && (
                                                    <Fragment>
                                                        {
                                                            choosedMenu == value.name && isShowSubList ? (
                                                                <span className='w-[12.5px]'>
                                                                    <ChevronUpIcon/>
                                                                </span>
                                                            ) : (
                                                                <span className='w-[12.5px]'>
                                                                    <ChevronDownIcon/>
                                                                </span>
                                                            )
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                        {
                                            value.sub_list.length > 0 && (
                                                <Fragment>
                                                    {
                                                        choosedMenu == value.name && isShowSubList && (
                                                            <ul className='flex flex-col px-4 pb-4 w-full'>
                                                                {
                                                                    value.sub_list.map((sub,subIndex) => (
                                                                        <li key={subIndex} onClick={() => sub.link ? window.open(sub.link, "_self") : moveToSection(vs.type)} className={`${montserratNormal.className} py-2`}>
                                                                            {sub.name}
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        )
                                                    }
                                                    
                                                </Fragment>
                                            )
                                        }
                                    </li>
                                )) : ""
                                })
                            }
                        </ul>
                        <ul className='flex w-full border-b border-gray-cool-300 justify-between p-4'>
                            {
                                (list_navbar.data.filter(v => v.position == "end")).map((val, index) => 
                                    (
                                        <>
                                            {
                                                val.position == "end" ? val.list.map((v,i) => (
                                                    // <div key={i} className='flex bg-semantic-positive-700'>
                                                        <div key={i}  className={`${i == 0 ? "px-6 py-3" : "rounded-lg px-6 py-3 border border-gray-cool-400"} ${montserratNormal.className}`}>
                                                            {
                                                                v.type == "signin" ? (
                                                                    <Link className={`${montserratNormal.className}`} href={v.link}>{v.name}</Link>   
                                                                ) : (
                                                                    <div onClick={() => moveToSection(v.type)} className={`${montserratNormal.className}`}>{v.name}</div>
                                                                )
                                                            }
                                                        </div>
                                                    // </div>
                                                )) : ""
                                            }
                                        </>
                                    )
                                )
                            }
                        </ul>
                    </Fragment>
                )
            }
        </nav>
    </Fragment>
  )
}

export default NavbarMobile